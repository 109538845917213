var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('k-field-group',{attrs:{"language-prefix":"stock.filters"}},[_c('KTextField',{attrs:{"type":"number","field":"stockMinimum","clearable":""},model:{value:(_vm.internalFilters.stockMinimum),callback:function ($$v) {_vm.$set(_vm.internalFilters, "stockMinimum", $$v)},expression:"internalFilters.stockMinimum"}}),_c('KSelect',{attrs:{"items":[
        { text: _vm.$t('stock.alert.none'), value: 'ZERO' },
        { text: _vm.$t('stock.alert.insufficient'), value: 'LOW' },
        { text: _vm.$t('stock.alert.sufficient'), value: 'SUFFICIENT' },
      ],"field":"stockStatus","clearable":""},model:{value:(_vm.internalFilters.stockStatus),callback:function ($$v) {_vm.$set(_vm.internalFilters, "stockStatus", $$v)},expression:"internalFilters.stockStatus"}}),_c('KSelect',{attrs:{"items":[
        { text: _vm.$t('stock.status.active'), value: _vm.StockStatus.Active },
        { text: _vm.$t('stock.status.created'), value: _vm.StockStatus.Created },
        { text: _vm.$t('stock.status.inactive'), value: _vm.StockStatus.Inactive },
      ],"field":"status","clearable":"","multiple":""},model:{value:(_vm.internalFilters.status),callback:function ($$v) {_vm.$set(_vm.internalFilters, "status", $$v)},expression:"internalFilters.status"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }